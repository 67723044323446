import React from 'react';
import './cta.css';

const CTA = () => (
  <div className="cta">
    <div className="cta-content">
      <p>View My Additional Projects</p>
      <h3>These are only a few of my projects, but here is where I keep the rest!</h3>
      <div className="cta-btn">
      <a href='/work'>All Projects</a>
    </div>
    </div>

  </div>
);

export default CTA;
