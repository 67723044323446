import React, { useEffect, useState } from "react";
import "./rocket.css";
import RocketPic from "../../assets/Rocket.svg";

function Rocket() {
const [offsetY, setOffsetY] = useState(0);
const handleScroll = () => setOffsetY(window.pageYOffset);
  
useEffect(() => {
  window.addEventListener('scroll', handleScroll);

  return () => window.removeEventListener("scroll", handleScroll);
}, []);


// const rocket = document.querySelector('.rocket');

 
//     const scrolled = window.pageYOffset;
//     const val = scrolled * 1.2;
//     rocket.style.transform = `translateY(${-2 * val}%)`;


  return (
    <section>
      <div className="second">
        <div className="logo-main">
          <img src={RocketPic} style={{ transform: `translateY(-${offsetY * 0.8}px)` }} class="rocket" alt="rocket svg graphic" />
        </div>
      </div>
    </section>
  );
}

export default Rocket;
