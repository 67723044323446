import React, { useState } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import { Link } from "react-router-dom";
import tamlogo from '../../logo.svg';
import './navbar.css';
import RocketPic from "../../assets/Rocket.svg";
const Navbar = () => {

  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className="navbar">
      <div className="navbar-links">
        <div className="navbar-links_logo">
          <img src={tamlogo} alt="Tamara Harris logo" />
        </div>
        <div className="navbar-links_container">
          <ul>
          <li>
          <Link to="/">Home</Link>
        </li>
            <li><Link to="/work">Work</Link></li>
            <li><a class="nav-rocket" href="https://blog.tamaraharris.dev">
      <div class="btn-rocket">
      <span>Blog</span>
      
      <i class="ico">
      <img src={RocketPic} class="icon" alt="rocket svg graphic" />
      </i>
     
      </div>
    </a>
            
            
            
            
            </li>
          </ul>
        </div>
      </div>
      {/* <div className="navbar-sign">
        <p>Sign in</p>
        <button type="button">Sign up</button>
      </div> */}
      <div className="navbar-menu">
        {toggleMenu
          ? <RiCloseLine color="#aa7af7" size={27} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line color="#aa7af7" size={27} onClick={() => setToggleMenu(true)} />}
        {toggleMenu && (
          <div className="navbar-menu_container scale-up-center">
            <div className="navbar-menu_container-links">
            <ul>
          <li>
          <Link to="/">Home</Link>
        </li>
            <li><Link to="/work">Work</Link></li>
          </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
