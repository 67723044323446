import React from 'react';
import tamlogo from '../../logo.svg';
import './footer.css';

const Footer = () => (
  <div className="footer section__padding">
    <div className="footer-heading">

      
      <h2>Let's Connect<span className="color-period">.</span></h2>
    </div>

    <a className="footer-btn" href="mailto:hello@tamaraharris.dev">
      <p>Send a Quick Email</p>
    </a>

    <div className="footer-links">
      <div className="footer-links_logo">
        <img src={tamlogo} alt="a logo of my initials" />
      </div>
      <div className="footer-links_div">
        <h4>LinkedIn</h4>
        <p>http://linkedin.com/in/tamaradharris/</p>
      </div>
    </div>

    <div className="footer-copyright">
      <p>{new Date().getFullYear()} – Designed and Created by Tamara Harris</p>
    </div>
  </div>
);

export default Footer;
