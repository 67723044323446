import React from 'react';
import './project.css';

const Project = ({ imgUrl, title, text, projectUrl }) => (
  <div className="projects-container_article">
    <div className="projects-container_article-image">
      <a href={projectUrl} target="_blank" rel="noopener noreferrer">
      <div className="sparkle_svg">
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 696 1080">
  <g id="top-star">
  <polygon points="573.94 223.12 590.27 277.76 644.91 294.09 590.27 310.42 573.94 365.06 557.61 310.42 502.97 294.09 557.61 277.76 573.94 223.12" fill="#aa7af7"/>
  </g>
  <g id="bottom-star">
  <polygon id="bottom-star-thing" points="97.22 643.16 113.55 697.8 168.19 714.13 113.55 730.46 97.22 785.1 80.89 730.46 26.25 714.13 80.89 697.8 97.22 643.16" fill="#aa7af7"
  
  type="rotate" from="0 350 150" to="360 350 150" begin="1s" dur="5s"/>
  </g>
  <g id="middle-star">
  <polygon points="626.98 540 633.16 560.67 653.83 566.85 633.16 573.03 626.98 593.7 620.8 573.03 600.13 566.85 620.8 560.67 626.98 540" fill="#aa7af7"/></g></svg>
        </div>
      <img src={imgUrl} alt="projects_image" />
      </a>
    </div>
    <div className="projects-container_article-content">
      <div>
        <h3>{title}</h3>
        <p>{text}</p>
      </div>
    </div>
  </div>
);

export default Project;
