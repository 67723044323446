import React from 'react';
import {  Navbar } from './components';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Home from "./components/pages/Home";
import Work from "./components/pages/Work";

const App = () => (
  
  <div className="App">
    <div>
    <BrowserRouter>
    <Navbar />
        <Routes>
        <Route path="/" element={<Home />} />
          <Route path="/work" element={<Work />}>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  </div>
);

export default App;
