import React from 'react';
import './header.css';
import headerimage from '../../assets/me-and-lightbulb.png';
import rocket from '../../assets/rocket-new.png';
import earth from '../../assets/earth.png';

function animatePaths() {
  var paths = document.querySelectorAll('.new-star');

  paths.forEach(function(path, index) {
    setTimeout(function() {
      path.style.animation = 'sparkle ' + (index * 3) + 's ease-in-out infinite';
    }, index * 200); // Change the delay time (in milliseconds) between each path
  });
}

// Call the function to start the animation
animatePaths();

const Header = () => (
  <div className="header " id="home">
    <div className="header-content">
      <h1>Hi, I'm Tamara<span className="color-period">.</span></h1>
      <h2>A Web Developer and Digital Arts Hybrid</h2>
      <p>My traditional art background allows me to enjoy the ins-and-outs of creating exceptional things on the web with the user in mind.</p>
      <div className="header-image-wrapper">
      <img className="light-bulb header-image" src={headerimage} alt="A graphic of girl sitting on lightbulb" />
      <img className="rocket-new header-image" src={rocket} alt="A graphic of rocket" />
      <img className="earth header-image" src={earth} alt="A grahpic of earth" />
      <div className="stars-new"> 
<svg id="stars-new" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2030.59 961.07">
  <path className="new-star" d="M819.08,44.38l2.71,19.58c0,.82.54,1.63,1.09,2.45.54.54,1.36,1.09,2.44,1.09l19.27,2.72-19.55,2.72c-.81,0-1.63.54-2.44,1.09-.54.54-1.09,1.36-1.09,2.45l-2.71,19.58-2.71-19.58c-.27-.82-.81-1.63-1.36-2.18-.54-.54-1.36-1.09-2.44-1.09l-19.27-2.72,19.55-2.72c.81-.27,1.63-.82,2.44-1.63.54-.54,1.09-1.36,1.09-2.45l2.99-19.3Z" fill="#f6f5f1" stroke-width="0px"/>
  <path className="new-star" d="M819.08,97.12h0c-.81,0-1.36-.54-1.36-1.09l-2.71-19.58c0-.27-.54-.82-.81-1.36-.54-.54-1.09-.82-1.63-.82l-19.27-2.72c-.54,0-1.09-.54-1.09-1.36,0-.54.54-1.36,1.09-1.36l19.55-2.72c.54,0,1.09-.54,1.36-1.09.54-.54.81-1.09.81-1.63l2.99-19.3c0-.54.54-1.09,1.36-1.09h0c.54,0,1.36.54,1.36,1.09l2.71,19.58c0,.54.27,1.09.81,1.63s1.09.82,1.63.82l19.27,2.72c.54,0,1.09.54,1.09,1.36,0,.54-.54,1.36-1.09,1.36l-19.55,2.72c-.54,0-1.09.27-1.63.82-.54.54-.81,1.09-.81,1.63l-2.71,19.3c-.27.54-.81,1.09-1.36,1.09ZM803.06,70.21l9.77,1.36c1.09.27,2.17.82,2.99,1.63.81.54,1.36,1.63,1.63,2.72l1.36,10.06,1.36-9.79c.27-1.09.81-2.18,1.63-3.26.81-.82,1.9-1.36,3.26-1.63l9.77-1.36-9.77-1.36c-1.09-.27-2.17-.82-2.99-1.63-.81-.82-1.36-1.9-1.63-3.26l-1.36-10.06-1.63,10.06c-.27,1.09-.81,2.18-1.63,2.99-.54.82-1.63,1.63-2.99,1.9l-9.77,1.63Z" fill="#1d2445" stroke-width="0px"/>
  <path className="new-star" d="M1449.16,373.88l3.8,25.56c.27,1.09.81,2.18,1.63,2.99.81.82,1.9,1.36,2.99,1.63l25.52,3.81-25.52,3.81c-2.44.27-4.34,2.18-4.62,4.62l-3.8,25.56-3.8-25.56c-.27-1.09-.81-2.18-1.63-2.99-.81-.82-1.9-1.36-2.99-1.63l-25.52-3.81,25.52-3.81c2.44-.27,4.34-2.18,4.62-4.62l3.8-25.56Z" fill="#f6f5f1" stroke-width="0px"/>
  <path className="new-star" d="M1449.16,443.21c-.54,0-1.36-.54-1.36-1.09l-3.8-25.56c0-.82-.54-1.63-1.09-2.45-.54-.54-1.36-1.09-2.44-1.09l-25.52-3.81c-.54,0-1.09-.54-1.09-1.36s.54-1.36,1.09-1.36l25.52-3.81c1.9-.27,3.26-1.63,3.53-3.53l3.8-25.56c0-.54.54-1.09,1.36-1.09h0c.54,0,1.36.54,1.36,1.09l3.8,25.56c0,.82.54,1.63,1.09,2.45.54.54,1.36,1.09,2.44,1.09l25.52,3.81c.54,0,1.09.54,1.09,1.36,0,.54-.54,1.36-1.09,1.36l-25.52,3.81c-1.9.27-3.26,1.63-3.53,3.53l-3.8,25.56c0,.54-.81,1.09-1.36,1.09h0ZM1424.72,407.86l16.29,2.45c1.36.27,2.71.82,3.8,1.9,1.09,1.09,1.63,2.45,1.9,3.81l2.44,16.31,2.44-16.31c.54-2.99,2.71-5.44,5.7-5.71l16.29-2.45-16.29-2.45c-1.36-.27-2.71-.82-3.8-1.9-1.09-1.09-1.63-2.45-1.9-3.81l-2.44-16.31-2.44,16.31c-.54,2.99-2.71,5.44-5.7,5.71l-16.29,2.45Z" fill="#1d2445" stroke-width="0px"/>
  <path className="new-star" d="M300.15,386.4l2.17,15.23c0,.82.54,1.36.81,1.9.54.54,1.09.82,1.9.82l15.47,2.18-15.47,2.18c-.81,0-1.36.54-1.9,1.09-.54.54-.81,1.09-.81,1.9l-2.17,15.23-2.17-15.23c0-.82-.54-1.36-.81-1.9-.54-.54-1.09-.82-1.9-.82l-15.47-2.18,15.47-2.18c.81,0,1.36-.54,1.9-1.09s.81-1.09.81-1.9l2.17-15.23Z" fill="#f6f5f1" stroke-width="0px"/>
  <path className="new-star" d="M300.15,428.54h0c-.54,0-1.36-.54-1.36-1.09l-2.17-15.23c0-.54-.27-.82-.54-1.09s-.54-.54-1.09-.54l-15.47-2.18c-.54,0-1.09-.54-1.09-1.36s.54-1.36,1.09-1.36l15.47-2.18c.54,0,.81-.27,1.09-.54.27-.27.54-.54.54-1.09l2.17-15.5c0-.54.54-1.09,1.36-1.09s1.36.54,1.36,1.09l2.17,15.23c0,.54.27.82.54,1.09.27.27.54.54,1.09.54l15.47,2.18c.54,0,1.09.54,1.09,1.36s-.54,1.36-1.09,1.36l-15.47,2.18c-.54,0-.81.27-1.09.54s-.54.54-.54,1.09l-2.17,15.5c0,.54-.81,1.09-1.36,1.09ZM289.29,406.79l5.97.82c1.09,0,1.9.54,2.71,1.36s1.09,1.63,1.36,2.72l.81,5.98.81-5.98c0-1.09.54-1.9,1.36-2.72s1.63-1.09,2.71-1.36l5.97-.82-5.97-.82c-1.09,0-1.9-.54-2.71-1.36s-1.09-1.63-1.36-2.72l-.81-5.98-.81,5.98c0,1.09-.54,1.9-1.36,2.72s-1.63,1.09-2.71,1.36l-5.97.82Z" fill="#1d2445" stroke-width="0px"/>
  <ellipse cx="511.2" cy="199.87" rx="15.75" ry="15.77" fill="#f6f5f1" stroke-width="0px"/>
  <path className="new-star" d="M511.2,209.39c-5.16,0-9.5-4.35-9.5-9.52s4.34-9.52,9.5-9.52,9.5,4.35,9.5,9.52-4.34,9.52-9.5,9.52ZM511.2,193.07c-3.8,0-6.79,2.99-6.79,6.8s2.99,6.8,6.79,6.8,6.79-2.99,6.79-6.8-2.99-6.8-6.79-6.8Z" fill="#1d2445" stroke-width="0px"/>
  <path className="new-star" d="M830.19,517.97l1.63,11.15c0,.54.27,1.09.81,1.36.27.27.81.54,1.36.82l11.13,1.63-11.13,1.63c-.54,0-1.09.27-1.36.82-.27.27-.54.82-.81,1.36l-1.63,11.15-1.63-11.15c0-.54-.27-1.09-.81-1.36-.27-.27-.81-.54-1.36-.82l-11.13-1.63,11.13-1.63c.54,0,1.09-.27,1.36-.82.27-.27.54-.82.81-1.36l1.63-11.15Z" fill="#f6f5f1" stroke-width="0px"/>
  <path className="new-star" d="M830.19,549.24h0c-.54,0-1.36-.54-1.36-1.09l-1.63-11.15c0-.27-.27-.54-.27-.54,0,0-.27-.27-.54-.27l-11.13-1.63c-.54,0-1.09-.54-1.09-1.36s.54-1.36,1.09-1.36l11.13-1.63c.27,0,.54-.27.54-.27,0,0,.27-.27.27-.54l1.63-11.15c0-.54.54-1.09,1.36-1.09h0c.54,0,1.36.54,1.36,1.09l1.63,11.15c0,.27.27.54.27.54,0,0,.27.27.54.27l11.13,1.63c.54,0,1.09.54,1.09,1.36s-.54,1.36-1.09,1.36l-11.13,1.63c-.27,0-.54.27-.54.27,0,0-.27.27-.27.54l-1.63,11.15c0,.54-.81,1.09-1.36,1.09ZM824.76,532.93l1.9.27c.81.27,1.63.54,2.17,1.09.54.54,1.09,1.36,1.09,2.18l.27,2.18.27-1.9c0-.82.54-1.63,1.09-2.18.54-.54,1.36-1.09,2.17-1.09l2.17-.27-1.9-.27c-.81-.27-1.63-.54-2.17-1.09-.54-.54-1.09-1.36-1.09-2.18l-.27-1.9-.27,1.9c-.27.82-.54,1.63-1.09,2.18-.54.54-1.36,1.09-2.17,1.09h-2.17Z" fill="#1d2445" stroke-width="0px"/>
  <path className="new-star" d="M381.51,720.31l3.26,21.48c0,1.09.54,1.9,1.36,2.72s1.63,1.09,2.71,1.36l21.45,2.99-21.45,2.99c-1.09,0-1.9.54-2.71,1.36s-1.09,1.63-1.36,2.72l-2.99,21.48-3.26-21.48c0-1.09-.54-1.9-1.36-2.72s-1.63-1.09-2.71-1.36l-21.45-2.99,21.45-2.99c1.09,0,1.9-.54,2.71-1.36s1.09-1.63,1.36-2.72l2.99-21.48Z" fill="#f6f5f1" stroke-width="0px"/>
  <path className="new-star" d="M381.51,778.77c-.54,0-1.36-.54-1.36-1.09l-3.26-21.48c0-.82-.54-1.36-.81-1.9-.54-.54-1.09-.82-1.9-.82l-21.45-2.99c-.54,0-1.09-.54-1.09-1.36s.54-1.36,1.09-1.36l21.45-2.99c.81,0,1.36-.27,1.9-.82s.81-1.09.81-1.9l2.99-21.48c0-.54.54-1.09,1.36-1.09h0c.54,0,1.36.54,1.36,1.09l3.26,21.48c0,.82.54,1.36.81,1.9.54.54,1.09.82,1.9.82l21.45,2.99c.54,0,1.09.54,1.09,1.36,0,.54-.54,1.36-1.09,1.36l-21.45,2.99c-.81,0-1.36.27-1.9.82s-.81,1.09-.81,1.9l-2.99,21.48c0,.54-.81,1.09-1.36,1.09h0ZM362.51,748.86l11.94,1.63c1.36.27,2.44.82,3.53,1.63.81.82,1.63,2.18,1.63,3.53l1.63,11.96,1.63-11.96c.27-1.36.81-2.45,1.63-3.53.81-1.09,2.17-1.63,3.53-1.63l11.94-1.63-11.94-1.63c-1.36-.27-2.44-.82-3.53-1.63-.81-.82-1.63-2.18-1.63-3.53l-1.63-11.96-1.63,11.96c-.27,1.36-.81,2.45-1.63,3.53-.81,1.09-2.17,1.63-3.53,1.63l-11.94,1.63Z" fill="#1d2445" stroke-width="0px"/>
  <path className="new-star" d="M241.15,836.94l1.63,11.15c0,.54.27,1.09.81,1.36.27.27.81.54,1.36.82l11.13,1.63-11.13,1.63c-.54,0-1.09.27-1.36.82-.27.27-.54.82-.81,1.36l-1.63,11.15-1.63-11.15c0-.54-.27-1.09-.81-1.36-.27-.27-.81-.54-1.36-.82l-11.13-1.63,11.13-1.63c.54,0,1.09-.27,1.36-.82.27-.27.54-.82.81-1.36l1.63-11.15Z" fill="#f6f5f1" stroke-width="0px"/>
  <path className="new-star" d="M241.15,868.21h0c-.54,0-1.36-.54-1.36-1.09l-1.63-11.15c0-.27-.27-.54-.27-.54,0,0-.27-.27-.54-.27l-11.13-1.63c-.54,0-1.09-.54-1.09-1.36s.54-1.36,1.09-1.36l11.13-1.63c.27,0,.54-.27.54-.27,0,0,.27-.27.27-.54l1.63-11.15c0-.54.54-1.09,1.36-1.09h0c.54,0,1.36.54,1.36,1.09l1.63,11.15c0,.27.27.54.27.54,0,0,.27.27.54.27l11.13,1.63c.54,0,1.09.54,1.09,1.36s-.54,1.36-1.09,1.36l-11.13,1.63c-.27,0-.54.27-.54.27,0,0-.27.27-.27.54l-1.63,11.15c0,.54-.81,1.09-1.36,1.09ZM235.72,851.9l1.9.27c.81,0,1.63.54,2.17,1.09.54.54,1.09,1.36,1.09,2.18l.27,2.18.27-1.9c0-.82.54-1.63,1.09-2.18.54-.54,1.36-1.09,2.17-1.09l2.17-.27-1.9-.27c-.81,0-1.63-.54-2.17-1.09-.54-.54-1.09-1.36-1.09-2.18l-.27-1.9-.27,1.9c-.27.82-.54,1.63-1.09,2.18-.54.54-1.36,1.09-2.17,1.09h-2.17Z" fill="#1d2445" stroke-width="0px"/>
  <path className="new-star" d="M743.31,314.06l2.17,15.5c0,.82.54,1.36,1.09,1.9s1.09.82,1.9.82l15.2,2.18-15.2,2.18c-.81,0-1.36.54-1.9.82-.54.54-.81,1.09-.81,1.9l-2.17,15.5-2.17-15.5c0-.82-.54-1.36-1.09-1.9s-1.09-.82-1.9-.82l-15.2-2.18,15.2-2.18c.81,0,1.36-.54,1.9-.82.54-.54.81-1.09.81-1.9l2.17-15.5Z" fill="#f6f5f1" stroke-width="0px"/>
  <path className="new-star" d="M743.31,356.2h0c-.54,0-1.36-.54-1.36-1.09l-2.17-15.5c0-.54-.27-.82-.54-1.09-.27-.27-.54-.54-1.09-.54l-15.47-2.18c-.54,0-1.09-.54-1.09-1.36s.54-1.36,1.09-1.36l15.2-2.18c.27,0,.81-.27,1.09-.54.27-.27.54-.54.54-1.09l2.17-15.5c0-.54.54-1.09,1.36-1.09s1.36.54,1.36,1.09l2.17,15.5c0,.54.27.82.54,1.09.27.27.54.54,1.09.54l15.47,2.18c.54,0,1.09.54,1.09,1.36s-.54,1.36-1.09,1.36l-15.2,2.18c-.54,0-.81.27-1.09.54-.27.27-.54.54-.54,1.09l-2.17,15.5c0,.54-.81,1.09-1.36,1.09ZM732.46,334.45l5.97.82c1.09,0,1.9.54,2.71,1.36s1.09,1.63,1.36,2.72l.81,5.98.81-5.98c0-1.09.54-1.9,1.36-2.72s1.63-1.09,2.71-1.36l5.97-.82-5.97-.82c-1.09,0-1.9-.54-2.71-1.36s-1.09-1.63-1.36-2.72l-.81-5.98-.81,5.98c0,1.09-.54,1.9-1.36,2.72s-1.63,1.09-2.71,1.36l-5.97.82Z" fill="#1d2445" stroke-width="0px"/>
  <path className="new-star" d="M1250.42,918.93l2.17,15.5c0,.82.54,1.36,1.09,1.9.54.54,1.09.82,1.9.82l15.2,2.18-15.2,2.18c-.81,0-1.36.54-1.9,1.09-.54.54-.81,1.09-.81,1.9l-2.17,15.23-2.17-15.23c0-.82-.54-1.36-1.09-1.9-.54-.54-1.09-.82-1.9-.82l-15.2-2.18,15.2-2.18c.81,0,1.36-.54,1.9-1.09.54-.54.81-1.09.81-1.9l2.17-15.5Z" fill="#f6f5f1" stroke-width="0px"/>
  <path className="new-star" d="M1250.42,961.07h0c-.54,0-1.36-.54-1.36-1.09l-2.17-15.23c0-.54-.27-.82-.54-1.09s-.54-.54-1.09-.54l-15.47-2.18c-.54,0-1.09-.54-1.09-1.36s.54-1.36,1.09-1.36l15.2-2.18c.27,0,.81-.27,1.09-.54s.54-.54.54-1.09l2.17-15.5c0-.54.54-1.09,1.36-1.09s1.36.54,1.36,1.09l2.17,15.5c0,.54.27.82.54,1.09s.54.54,1.09.54l15.47,2.18c.54,0,1.09.54,1.09,1.36s-.54,1.36-1.09,1.36l-15.2,2.18c-.54,0-.81.27-1.09.54s-.54.54-.54,1.09l-2.17,15.5c0,.27-.81.82-1.36.82ZM1239.56,939.32l5.97.82c1.09,0,1.9.54,2.71,1.36.81.82,1.09,1.63,1.36,2.72l.81,5.98.81-5.98c0-1.09.54-1.9,1.36-2.72s1.63-1.09,2.71-1.36l5.97-.82-5.97-.82c-1.09,0-1.9-.54-2.71-1.36s-1.09-1.63-1.36-2.72l-.81-5.98-.81,5.98c0,1.09-.54,1.9-1.36,2.72s-1.63,1.09-2.71,1.36l-5.97.82Z" fill="#1d2445" stroke-width="0px"/>
  <path className="new-star" d="M1048.73,501.93l2.17,15.23c0,.82.54,1.36,1.09,1.9.54.54,1.09.82,1.9.82l15.2,2.18-15.2,2.18c-.81,0-1.36.54-1.9,1.09-.54.54-.81,1.09-.81,1.9l-2.17,15.23-2.17-15.23c0-.82-.54-1.36-1.09-1.9-.54-.54-1.09-.82-1.9-.82l-15.2-2.18,15.2-2.18c.81,0,1.36-.54,1.9-1.09.54-.54.81-1.09.81-1.9l2.17-15.23Z" fill="#f6f5f1" stroke-width="0px"/>
  <path className="new-star" d="M1048.73,544.07h0c-.54,0-1.36-.54-1.36-1.09l-2.17-15.23c0-.54-.27-.82-.54-1.09s-.54-.54-1.09-.54l-15.47-2.18c-.54,0-1.09-.54-1.09-1.36s.54-1.36,1.09-1.36l15.2-2.18c.27,0,.81-.27,1.09-.54s.54-.54.54-1.09l2.17-15.5c0-.54.54-1.09,1.36-1.09s1.36.54,1.36,1.09l2.17,15.23c0,.54.27.82.54,1.09s.54.54,1.09.54l15.47,2.18c.54,0,1.09.54,1.09,1.36s-.54,1.36-1.09,1.36l-15.2,2.18c-.54,0-.81.27-1.09.54s-.54.54-.54,1.09l-2.17,15.5c-.27.82-.81,1.09-1.36,1.09ZM1037.87,522.32l5.97.82c1.09,0,1.9.54,2.71,1.36s1.09,1.63,1.36,2.72l.81,5.98.81-5.98c0-1.09.54-1.9,1.36-2.72s1.63-1.09,2.71-1.36l5.97-.82-5.97-.82c-1.09,0-1.9-.54-2.71-1.36s-1.09-1.63-1.36-2.72l-.81-5.98-.81,5.98c0,1.09-.54,1.9-1.36,2.72s-1.63,1.09-2.71,1.36l-5.97.82Z" fill="#1d2445" stroke-width="0px"/>
  <path className="new-star" d="M1164.1,403.78l4.07,27.73c.27,1.36.81,2.45,1.63,3.26.81.82,2.17,1.36,3.26,1.63l27.42,4.08-27.69,4.08c-2.71.27-4.62,2.45-5.16,5.17l-4.07,27.46-4.07-27.73c-.27-1.36-.81-2.45-1.63-3.26-.81-.82-2.17-1.36-3.26-1.63l-27.42-4.08,27.69-4.08c2.71-.27,4.62-2.45,5.16-4.89l4.07-27.73Z" fill="#f6f5f1" stroke-width="0px"/>
  <path className="new-star" d="M1164.1,478.55c-.54,0-1.36-.54-1.36-1.09l-4.07-27.73c-.27-1.09-.54-1.9-1.36-2.72s-1.63-1.09-2.71-1.36l-27.42-4.08c-.54,0-1.09-.54-1.09-1.36,0-.54.54-1.36,1.09-1.36l27.69-4.08c1.9-.27,3.53-1.9,3.8-3.81l4.07-27.46c0-.54.54-1.09,1.36-1.09h0c.54,0,1.36.54,1.36,1.09l4.07,27.73c.27,1.09.54,1.9,1.36,2.72s1.63,1.09,2.71,1.36l27.42,4.08c.54,0,1.09.54,1.09,1.36s-.54,1.36-1.09,1.36l-27.69,4.08c-1.9.27-3.53,1.9-3.8,3.81l-4.07,27.46c0,.54-.81,1.09-1.36,1.09h0ZM1136.96,440.49l18.46,2.72c1.63.27,2.99,1.09,4.07,2.18s1.9,2.72,2.17,4.08l2.71,18.49,2.71-18.49c.54-3.26,2.99-5.71,6.24-6.25l18.46-2.72-18.46-2.72c-1.63-.27-2.99-1.09-4.07-2.18-1.09-1.09-1.9-2.72-2.17-4.08l-2.71-18.49-2.71,18.49c-.54,3.26-2.99,5.71-6.24,6.25l-18.46,2.72Z" fill="#1d2445" stroke-width="0px"/>
  <ellipse cx="1035.15" cy="172.68" rx="10.59" ry="10.6" fill="#f6f5f1" stroke-width="0px"/>
  <path className="new-star" d="M1035.15,179.48c-3.8,0-6.79-2.99-6.79-6.8s2.99-6.8,6.79-6.8,6.79,2.99,6.79,6.8-2.99,6.8-6.79,6.8ZM1035.15,168.6c-2.17,0-4.07,1.9-4.07,4.08s1.9,4.08,4.07,4.08,4.07-1.9,4.07-4.08-1.9-4.08-4.07-4.08Z" fill="#1d2445" stroke-width="0px"/>
  <ellipse cx="1453.65" cy="874.17" rx="10.59" ry="10.6" fill="#f6f5f1" stroke-width="0px"/>
  <path className="new-star" d="M1453.65,880.97c-3.8,0-6.79-2.99-6.79-6.8s2.99-6.8,6.79-6.8,6.79,2.99,6.79,6.8-2.99,6.8-6.79,6.8ZM1453.65,870.09c-2.17,0-4.07,1.9-4.07,4.08s1.9,4.08,4.07,4.08,4.07-1.9,4.07-4.08-1.9-4.08-4.07-4.08Z" fill="#1d2445" stroke-width="0px"/>
  <ellipse cx="1312.06" cy="188.99" rx="20.9" ry="20.94" fill="#f6f5f1" stroke-width="0px"/>
  <path className="new-star" d="M1312.06,201.23c-6.79,0-12.22-5.44-12.22-12.23s5.43-12.23,12.22-12.23,12.22,5.44,12.22,12.23-5.43,12.23-12.22,12.23ZM1312.06,179.48c-5.16,0-9.5,4.35-9.5,9.52s4.34,9.52,9.5,9.52,9.5-4.35,9.5-9.52-4.34-9.52-9.5-9.52Z" fill="#1d2445" stroke-width="0px"/>
  <ellipse cx="750.1" cy="607.7" rx="15.75" ry="15.77" fill="#f6f5f1" stroke-width="0px"/>
  <path className="new-star" d="M750.1,617.21c-5.16,0-9.5-4.35-9.5-9.52s4.34-9.52,9.5-9.52,9.5,4.35,9.5,9.52c0,5.44-4.34,9.52-9.5,9.52ZM750.1,600.9c-3.8,0-6.79,2.99-6.79,6.8s2.99,6.8,6.79,6.8,6.79-2.99,6.79-6.8c0-3.53-2.99-6.8-6.79-6.8Z" fill="#1d2445" stroke-width="0px"/>
  <ellipse cx="438.13" cy="491.23" rx="10.59" ry="10.6" fill="#f6f5f1" stroke-width="0px"/>
  <path className="new-star" d="M438.13,498.02c-3.8,0-6.79-2.99-6.79-6.8s2.99-6.8,6.79-6.8,6.79,2.99,6.79,6.8-2.99,6.8-6.79,6.8ZM438.13,487.15c-2.17,0-4.07,1.9-4.07,4.08s1.9,4.08,4.07,4.08,4.07-1.9,4.07-4.08-1.9-4.08-4.07-4.08Z" fill="#1d2445" stroke-width="0px"/>
  </svg>
      
      
      </div>
      </div>
    

      <div class="star-container">
        <div id="stars"></div>
        <div id="stars2"></div>
        <div id="stars3"></div>
      </div>

    </div>


  </div>
);

export default Header;
