import React from 'react';
import { Footer, Projects, About, Header, Technologies } from '../../containers';
import { CTA, Skills } from '../../components';
import './home.css';

const Home = () => (
  
  <div className="Home">
    <div>
      <Header />
    </div>
    <Projects />
    <Skills />
    <Technologies />
    <About />
    <CTA />
    <Footer />
  </div>
);

export default Home;