import React from 'react';
import { GraphicDesign, Illustrator, Backend, Frontend } from './imports';
import './skills.css';

const Skills = () => (
  <div className="skills section__padding">
    <h3>Skillset<span className="color-period">.</span></h3>
    <div className="skills-content">
      <div class="icon-wrapper">
        <img src={Frontend} alt="Frontend" />
        <h5>Front-End<br />Development</h5>
      </div>
      <div className="icon-wrapper">
        <img src={GraphicDesign} alt="GraphicDesign" />
        <h5>Graphic Design</h5>
      </div>
      <div className="icon-wrapper">
        <img src={Backend} alt="Backend" />
        <h5>Back-End<br />Developement</h5>
      </div>

      <div className="icon-wrapper">
        <img src={Illustrator} alt="Illustrator" />
        <h5>Illustration</h5>
      </div>


    </div>
  </div>
);

export default Skills;
