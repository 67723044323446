import React from "react";
import "./experience.css";

const Experience = () => (
  <div className="experience">
    <div className="experience-heading">
      <h2>Experience<span className="color-period">.</span></h2>
    </div>
    <div className="experience-wrapper">
      <ul>
        <li>
          <div>
            <div className="title">Front End Web Developer</div>
            <div className="info">
              WordPress &bull; Customer Service &bull; UI/UX Designer &bull; Internal Experience
            </div>
            <div className="type">Wake Forest University</div>
          </div>
          <span className="number">
            {" "}
            <span>Jan '23</span>
          </span>
        </li>
        <li>
          <div>
            <div className="title">Front End Web Developer</div>
            <div className="info">
              HubSpot Developer Certified &bull; Soft Skills &bull; Early Learning Experience
            </div>
            <div className="type">Kaplan Early Learning Company</div>
          </div>
          <span className="number">
            {" "}
            <span>Jul '22</span>
          </span>
        </li>
        <li>
          <div>
            <div className="title">Web Specialist</div>
            <div className="info">
              HubSpot Certified &bull; Soft Skills &bull; Marketing Agency Experience{" "}
            </div>
            <div className="type">Stratagon Marketing Agency</div>
          </div>
          <span className="number">
            {" "}
            <span>Mar '21</span>
          </span>
        </li>
        <li>
          <div>
            <div className="title">Team Developer</div>
            <div className="info">
              Team Programming &bull; React & Firebase &bull; Collaboration
              &bull; Soft Skills
            </div>
            <div className="type">Collab Lab</div>
          </div>{" "}
          <span className="number">
            {" "}
            <span>Mar '21</span>
          </span>
        </li>
        <li>
          <div>
            <div className="title">Developer Team Voyage</div>
            <div className="info">
              Team Programming &bull; Agile Methodology &bull; Code Reviews
              &bull; Time Management
            </div>
            <div className="type">Chingu</div>
          </div>{" "}
          <span className="number">
            {" "}
            <span>Feb '21</span>
          </span>
        </li>
        <li>
          <div>
            <div className="title">Kong x Coding Black Females Fellowship</div>
            <div className="info">
              Mentorship &bull; Resume Building &bull; Networking{" "}
            </div>
            <div className="type">Coding Black Females</div>
          </div>{" "}
          <span className="number">
            {" "}
            <span>Jan '21</span>
          </span>
        </li>
        <li>
          <div>
            <div className="title">The Complete Python Course</div>
            <div className="info">Python &bull; PyCharm</div>
            <div className="type">Udemy</div>
          </div>{" "}
          <span className="number">
            <span>Dec '20</span>
          </span>
        </li>
        <li>
          <div>
            <div className="title">Web Development Bootcamp</div>
            <div className="info">
              Node &bull; ESJ &bull; MongoDB &bull; MySQL &bull; React
            </div>
            <div className="type">Udemy</div>
          </div>{" "}
          <span className="number">
            <span>Feb '19</span>
          </span>
        </li>
        <li>
          <div>
            <div className="title">Front End Developer Blueprint</div>
            <div className="info">
              HTML &bull; CSS &bull; Bootstrap &bull; JS &bull; Git|GitHub{" "}
            </div>
            <div className="type">Skillcrush</div>
          </div>{" "}
          <span className="number">
            {" "}
            <span>May '18</span>
          </span>
        </li>
      </ul>
    </div>
  </div>
);

export default Experience;
