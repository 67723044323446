import React from 'react';
import Feature from '../../components/feature/Feature';
import { Monitor, HubSpot, Javascript, WordPress, CreativeCloud } from '../../components/skills/imports';
import './technologies.css';

const Technologies = () => (
  <div className="technologies section__margin" id="wgpt3">
    <div className="technologies-heading">
      <h2>Technologies<span className="color-period">.</span></h2>
    </div>
    <div id="circle-orbit-container">
      <img class="monitor" src={Monitor} alt="Graphic of a computer monitor" />

      {/* <!-- Circles closest to the central point --> */}
      <div id="inner-orbit">
        <div class="inner-orbit-circles">
          <div className="inner-orbit-icon planet" style={{ backgroundImage: `url(${Javascript})` }}></div>
        </div>
      </div>

      {/* <!-- Circles second closest to the central point --> */}
      <div id="middle-orbit">
        <div class="middle-orbit-circles">
          <div className="middle-orbit-icon planet" style={{ backgroundImage: `url(${HubSpot})` }}></div>
        </div>
        <div class="middle-orbit-circles two">
          <div className="middle-orbit-icon planet" style={{ backgroundImage: `url(${WordPress})` }}></div>
        </div>
      </div>

      {/* <!-- Circles furthest away to the central point --> */}
      <div id="outer-orbit">
        <div class="outer-orbit-circles">
          <div className="outer-orbit-icon planet" style={{ backgroundImage: `url(${CreativeCloud})` }}></div>
        </div>

      </div>

    </div>

    <div className="technologies-feature">
      <Feature title="Javascript" text="The programming language of the web. After getting a grasp of the fundamentals and daily practice, I’ve grown to love it. It’s very versatile and my main focus is to master it." />
    </div>

    <div className="technologies-container">
      <Feature title="Adobe Creative Suite" text="It’s my main arsenal for different digital mediums. I mainly use XD, Illustrator, Photoshop, and Premier Rush.
" />
      <Feature title="HubSpot" text="For streamlining Web Development and client handoff. HubSpot also compliments the traditional coding environment." />
      <Feature title="Wordpress" text="Another tried-and-true CMS that I enjoy for client handoff. It’s a simple platform that is tailored to the needs of the client." />
    </div>
  </div>
);

export default Technologies;
