import React from 'react';
import Article from '../../components/project/Project';
import { projectphoto01, projectphoto02, projectphoto03, projectphoto04, projectphoto05, projectphoto06 } from './imports';
import Experience from '../../components/experience/Experience';
import Footer from '../footer/Footer';
import './projectsall.css';

const Projects = () => (
<div className="projects section__padding" id="projects">
  <div className="projectsall-heading">
    <h2>All Projects<span className="color-period">.</span></h2>
  </div>
  <div className="projects-container">
    <div className="projects-container_groupB">
    <Article imgUrl={projectphoto01} title="Expense Tracking App" projectUrl="https://tamaraharris.dev/expense-tracking-app/" text="A full and dynamic PHP app, with login, that can store and display one's finances for easier understanding and management." />
    <Article imgUrl={projectphoto04} title="Dodo Codes" projectUrl="https://dodocodes.vercel.app/" text="A React App with Firebase database, Google login, and censored functionality. Users can submit their Animal Crossing island code for others to see!" />
    <Article imgUrl={projectphoto06} title="A Business Website" projectUrl="https://nailsbytam.com" text="A manicuring business website built and managed with WordPress. Including a blog and an ecommerce store." />
  <Article imgUrl={projectphoto02} title="Task App" projectUrl="https://task-vault.vercel.app/" text="A React App using the Material UI library and demonstrating full functionality and animations." />

  <Article imgUrl={projectphoto03} title="The Simon Game" projectUrl="https://simon-game-2ud9hlmvg-codenametam.vercel.app" text="The classic game of Simon made from Javascript and Jquery" />
    <Article imgUrl={projectphoto05} title="Drum Kit" projectUrl="https://distracted-leakey-83ed70.netlify.app" text="A Javascript practice app that allows user to push keys on a keyboard to activate sounds." />
    </div>
  </div>
  <Experience />
  <Footer />
</div>
);

export default Projects;
