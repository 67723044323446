import React from 'react';
import Article from '../../components/project/Project';
import { projectphoto01, projectphoto06, projectphoto04, linetransition } from './imports';
import './projects.css';

const Projects = () => (
  <div className="projects section__padding" id="projects">
    <img className="line-transition" src={linetransition} alt="" />
    <div className="projects-heading">
      <h2>Projects<span className="color-period">.</span></h2>
    </div>
    <div className="projects-container">
      <div className="projects-container_groupB">
      <Article imgUrl={projectphoto01} title="Expense Tracking App" projectUrl="https://tamaraharris.dev/expense-tracking-app/" text="A full and dynamic PHP app, with login, that can store and display one's finances for easier understanding and management." />
      <Article imgUrl={projectphoto04} title="Dodo Codes" projectUrl="https://dodocodes.vercel.app/" text="A React App with Firebase database, Google login, and censored functionality. Users can submit their Animal Crossing island code for others to see!" />
    <Article imgUrl={projectphoto06} title="A Business Website" projectUrl="https://nailsbytam.com" text="A manicuring business website built and managed with WordPress. Including a blog and an ecommerce store." />
      </div>
    </div>
    
  </div>
);

export default Projects;
