import React from 'react';
import ProjectsAll from '../../containers/projectsall/ProjectsAll';

function Work() {
    
    return (
        <div>
            <ProjectsAll />
        </div>

    );
}

export default Work;